var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { baseReducers } from 'common/utils/base-reduсer';
import { USER_SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES } from '../../services/constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: null });
var surpriseBoxDetailServiceState = createSlice({
    name: USER_SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES.surpriseBoxDetail,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, archiveSurpriseBox: function (state, action) {
            var _a;
            if (((_a = state.data) === null || _a === void 0 ? void 0 : _a.id) === action.payload.id)
                state.data = __assign(__assign({}, state.data), { isArchived: true });
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = surpriseBoxDetailServiceState.actions;
export default surpriseBoxDetailServiceState.reducer;
