var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { SURPRISE_BOX_FORM_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, pageSize: 25 });
var productsForSelectServiceState = createSlice({
    name: SURPRISE_BOX_FORM_NAMESPACES.productsForSelect,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data.total = total;
            state.data.pageData = pageData;
            state.data.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state.data.content, true), content, true) : __spreadArray([], content, true);
            if (!state.initialized)
                state.initialized = true;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = productsForSelectServiceState.actions;
export default productsForSelectServiceState.reducer;
