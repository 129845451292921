var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from 'react-router-dom';
import { RolesRoute } from 'common/components/roles-route';
import { getRegistrationRoutes, getGuestRoutes, getUserSelfRegistrationRoutes, getBasicRoutes, getUserRoutes, } from 'common/constants/routes';
var ROUTES_CB_MAP = {
    registration: getRegistrationRoutes,
    userRoutes: getUserRoutes,
    basic: getBasicRoutes,
    guest: getGuestRoutes,
    userSelfRegistration: getUserSelfRegistrationRoutes,
};
var getRouteElement = function (_a) {
    var index = _a.index, path = _a.path, Component = _a.component, title = _a.title, owner = _a.owner, featureToggleKey = _a.featureToggleKey, checkAvailable = _a.checkAvailable;
    return (_jsx(Route, { index: index, path: !index ? path : undefined, element: _jsx(RolesRoute, __assign({}, { title: title, owner: owner, featureToggleKey: featureToggleKey, checkAvailable: checkAvailable }, { children: _jsx(Component, {}) })) }, path));
};
export var getPartRoutes = function (variant) {
    var baseRoutes = {
        adaptivePages: [],
        notAdaptivePages: [],
        withoutBaseMenuPages: [],
    };
    var routes = ROUTES_CB_MAP[variant]().reduce(function (acc, currentEl) {
        var isAdaptivePage = currentEl.isAdaptivePage, withoutBaseMenu = currentEl.withoutBaseMenu, isNeedToHidePage = currentEl.isNeedToHidePage;
        if (isNeedToHidePage) {
            return acc;
        }
        var adaptivePages = acc.adaptivePages, notAdaptivePages = acc.notAdaptivePages, withoutBaseMenuPages = acc.withoutBaseMenuPages;
        var route = getRouteElement(currentEl);
        if (withoutBaseMenu) {
            withoutBaseMenuPages.push(route);
        }
        else if (isAdaptivePage) {
            adaptivePages.push(route);
        }
        else {
            notAdaptivePages.push(route);
        }
        return { adaptivePages: adaptivePages, notAdaptivePages: notAdaptivePages, withoutBaseMenuPages: withoutBaseMenuPages };
    }, baseRoutes);
    return routes;
};
