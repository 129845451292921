var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cs from 'classnames';
import { omit } from 'common/helpers/omit';
import { capitalizeFirstLetter } from 'common/helpers/capitalize-first-latter';
import './box.scss';
var DEFAULT_COMPONENT = 'div';
var PREFIXES = ['sm', 'md', 'lg', 'xl'];
var KEYS_MAP = [
    ['display'],
    ['direction'],
    ['size'],
    ['wrap'],
    ['justify'],
    ['alignItems', 'align-items'],
    ['alignSelf', 'align-self'],
    ['alignSelf', 'align-self'],
    ['alignContent', 'align-content'],
    ['position'],
    ['paddingAll', 'padding-all'],
    ['px', 'padding-x'],
    ['py', 'padding-y'],
    ['pt', 'padding-top'],
    ['pr', 'padding-right'],
    ['pb', 'padding-bottom'],
    ['pl', 'padding-left'],
    ['mt', 'margin-top'],
    ['mr', 'margin-right'],
    ['mb', 'margin-bottom'],
    ['ml', 'margin-left'],
    ['marginAll', 'margin-all'],
    ['mx', 'margin-x'],
    ['my', 'margin-y'],
    ['marginAuto', 'margin-auto'],
    ['gap'],
];
var getPreparedProps = function (props, keys) {
    var classes = '';
    var classesProps = [];
    keys.forEach(function (_a) {
        var key = _a[0], alias = _a[1];
        if (key in props) {
            classes += !!props[key] ? "".concat(String(alias !== null && alias !== void 0 ? alias : key), "-").concat(props[key], " ") : '';
            classesProps.push(key);
        }
        PREFIXES.forEach(function (screen) {
            var k = "".concat(String(key)).concat(capitalizeFirstLetter(screen));
            if (k in props) {
                classes += !!props[k] ? "".concat(screen, "-").concat(alias !== null && alias !== void 0 ? alias : String(key), "-").concat(props[k], " ") : '';
                classesProps.push(k);
            }
        });
    });
    return [classes.trim(), omit(props, classesProps)];
};
export var Box = function (_a) {
    var component = _a.component, hidden = _a.hidden, width = _a.width, maxWidth = _a.maxWidth, height = _a.height, minHeight = _a.minHeight, flexBasis = _a.flexBasis, dataTestid = _a.dataTestid, className = _a.className, style = _a.style, children = _a.children, onClick = _a.onClick, onBlur = _a.onBlur, rest = __rest(_a, ["component", "hidden", "width", "maxWidth", "height", "minHeight", "flexBasis", "dataTestid", "className", "style", "children", "onClick", "onBlur"]);
    var _b = getPreparedProps(rest, KEYS_MAP), preparedClasses = _b[0], restProps = _b[1];
    var classes = cs('box', {
        hidden: hidden,
    }, preparedClasses, className);
    var Component = component || DEFAULT_COMPONENT;
    return (_jsx(Component, __assign({ className: classes, style: __assign({ width: width, maxWidth: maxWidth, height: height, minHeight: minHeight, flexBasis: flexBasis }, (style !== null && style !== void 0 ? style : {})), "data-testid": dataTestid, onClick: onClick, onBlur: onBlur }, restProps, { children: children })));
};
