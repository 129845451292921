var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var BASE_FILTERS_INITIAL_STATE = {
    positionIds: [],
    employeeTagIds: [],
    birthdaysInterval: null,
};
export var getInitialValuesByFllterType = function (filterType) {
    if (['employeesCount', 'staffTurnover'].includes(filterType))
        return __assign({ datesInterval: null, selectedIntervalVariant: null }, BASE_FILTERS_INITIAL_STATE);
    return __assign({}, BASE_FILTERS_INITIAL_STATE);
};
