export var OrderReceiveType;
(function (OrderReceiveType) {
    OrderReceiveType["Courier"] = "Courier";
    OrderReceiveType["Pickup"] = "Pickup";
    OrderReceiveType["Email"] = "Email";
})(OrderReceiveType || (OrderReceiveType = {}));
export var OrderStatusType;
(function (OrderStatusType) {
    OrderStatusType["Created"] = "Created";
    OrderStatusType["InProgress"] = "InProgress";
    OrderStatusType["Sent"] = "Sent";
    OrderStatusType["Closed"] = "Closed";
    OrderStatusType["Canceled"] = "Canceled";
    OrderStatusType["Draft"] = "Draft";
    OrderStatusType["ProductRefusal"] = "ProductRefusal";
})(OrderStatusType || (OrderStatusType = {}));
export var RequestStatusType;
(function (RequestStatusType) {
    RequestStatusType["InProgress"] = "InProgress";
    RequestStatusType["Confirmed"] = "Confirmed";
    RequestStatusType["Declined"] = "Declined";
})(RequestStatusType || (RequestStatusType = {}));
export var ThankRatingType;
(function (ThankRatingType) {
    ThankRatingType["GivenThanks"] = "GivenThanks";
    ThankRatingType["ReceivedThanks"] = "ReceivedThanks";
    ThankRatingType["TransferredCurrency"] = "TransferredCurrency";
    ThankRatingType["ReceivedCurrency"] = "ReceivedCurrency";
})(ThankRatingType || (ThankRatingType = {}));
