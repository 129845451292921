var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cs from 'classnames';
import { Box } from '../box';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { InfoCircleIcon } from './icons';
import styles from './info-block.module.scss';
export var InfoBlock = function (_a) {
    var _b;
    var text = _a.text, type = _a.type, textGap = _a.textGap, customIcon = _a.customIcon, isFullWidth = _a.isFullWidth, isWithoutIcon = _a.isWithoutIcon, className = _a.className, textClassName = _a.textClassName, style = _a.style, textStyle = _a.textStyle, children = _a.children, rest = __rest(_a, ["text", "type", "textGap", "customIcon", "isFullWidth", "isWithoutIcon", "className", "textClassName", "style", "textStyle", "children"]);
    return (_jsxs(Box, __assign({ className: cs(styles.infoBlock, styles[type || 'def'], (_b = {}, _b[styles.fullWidth] = isFullWidth, _b), className), paddingAll: 5, pl: 4, display: "flex", gap: textGap !== null && textGap !== void 0 ? textGap : 4, alignItems: "center", wrap: "nowrap", style: style }, { children: [customIcon ||
                (!isWithoutIcon && _jsx(Icon, { className: styles.icon, component: InfoCircleIcon, color: type || 'primary' })), _jsxs(Typography, __assign({ className: cs(styles.infoWrapper, textClassName), variant: "body-s", color: type || 'main', style: textStyle }, rest, { children: [text, children] }))] })));
};
