var _a;
import { combineReducers } from 'redux';
import productsServiceReducer from './products-service/reducer';
import productsFilterServiceReducer from './products-filter-service/reducer';
import createOrderProductFormServiceReducer from './create-order-product-service/reducer';
import { PLACE_MARKET_SERVICES_REDUCER_NAMESPACES } from './constants';
export var placeMarketServicesRootReducer = combineReducers((_a = {},
    _a[PLACE_MARKET_SERVICES_REDUCER_NAMESPACES.filter] = productsFilterServiceReducer,
    _a[PLACE_MARKET_SERVICES_REDUCER_NAMESPACES.products] = productsServiceReducer,
    _a[PLACE_MARKET_SERVICES_REDUCER_NAMESPACES.createOrderProductForm] = createOrderProductFormServiceReducer,
    _a));
