var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { getInitialValuesByFllterType } from './helpers';
import { TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES } from '../constants';
var INITIAL_STATE = {
    employeesCount: getInitialValuesByFllterType('employeesCount'),
    staffTurnover: getInitialValuesByFllterType('staffTurnover'),
    employeesExperience: getInitialValuesByFllterType('employeesExperience'),
    employeesAge: getInitialValuesByFllterType('employeesAge'),
    initialDatesInterval: {},
    initialSelectedIntervalVariant: {},
};
var employeesStatisticsFilterServiceState = createSlice({
    name: TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesStatisticsFilter,
    initialState: INITIAL_STATE,
    reducers: {
        setFilterValues: function (state, action) {
            state[action.payload.filterType] = action.payload.filterValues;
        },
        setInterval: function (state, action) {
            var _a = action.payload, filterType = _a.filterType, datesInterval = _a.datesInterval, isInitial = _a.isInitial, cb = _a.cb;
            cb === null || cb === void 0 ? void 0 : cb(datesInterval);
            if (isInitial)
                state.initialDatesInterval[filterType] = datesInterval;
            state[filterType].datesInterval = datesInterval;
        },
        setIntervalVariant: function (state, action) {
            var _a = action.payload, filterType = _a.filterType, isInitial = _a.isInitial, intervalVariant = _a.intervalVariant;
            if (isInitial)
                state.initialSelectedIntervalVariant[filterType] = intervalVariant;
            state[filterType].selectedIntervalVariant = intervalVariant;
        },
        removeEmployeeTagId: function (state, action) {
            var _a = action.payload, tagId = _a.tagId, filterType = _a.filterType;
            state[filterType].employeeTagIds = state[filterType].employeeTagIds.filter(function (id) { return tagId !== id; });
        },
        removePositionId: function (state, action) {
            var _a = action.payload, positionId = _a.positionId, filterType = _a.filterType;
            state[filterType].positionIds = state[filterType].positionIds.filter(function (id) { return positionId !== id; });
        },
        resetBirthdaysInterval: function (state, action) {
            state[action.payload].birthdaysInterval = null;
        },
        resetFilterValues: function (state, action) {
            state[action.payload] = __assign(__assign({}, INITIAL_STATE[action.payload]), { datesInterval: state.initialDatesInterval[action.payload], selectedIntervalVariant: state.initialSelectedIntervalVariant[action.payload] });
        },
        resetState: function () { return INITIAL_STATE; },
    },
});
export var actions = employeesStatisticsFilterServiceState.actions;
export default employeesStatisticsFilterServiceState.reducer;
